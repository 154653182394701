import React, { Component } from 'react';
import {Link} from 'react-router-dom';

// Components
import MenuSVG from '../svg/menu';

// Styles
import './menu.css';

class Menu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: false,
        }
        this.handleOpenClick = this.handleOpenClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCloseClick = this.handleCloseClick.bind(this);
    }

    handleOpenClick() {
        this.setState({ menu: true });
    }

    handleCloseClick() {
        this.setState({ menu: false });
    }

    handleClick(id) {
        function currentYPosition() {
            // Firefox, Chrome, Opera, Safari
            if (window.pageYOffset) return window.pageYOffset;
            // Internet Explorer 6 - standards mode
            if (document.documentElement && document.documentElement.scrollTop)
                return document.documentElement.scrollTop;
            // Internet Explorer 6, 7 and 8
            if (document.body.scrollTop) return document.body.scrollTop;
            return 0;
        }


        function elmYPosition(eID) {
            var elm = document.getElementById(eID);
            if (elm) {
                var y = elm.offsetTop;
                var node = elm;
                while (node.offsetParent && node.offsetParent !== document.body) {
                    node = node.offsetParent;
                    y += node.offsetTop;
                } return y;
            }
        }


        function smoothScroll(eID) {
            var startY = currentYPosition();
            var stopY = elmYPosition(eID);
            var distance = stopY > startY ? stopY - startY : startY - stopY;
            if (distance < 100) {
                window.scrollTo(0, stopY); return;
            }
            var speed = Math.round(distance / 100);
            if (speed >= 20) speed = 20;
            var step = Math.round(distance / 25);
            var leapY = stopY > startY ? startY + step : startY - step;
            var timer = 0;
            if (stopY > startY) {
                for ( var i=startY; i<stopY; i+=step ) {
                    setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                    leapY += step; if (leapY > stopY) leapY = stopY; timer++;
                } return;
            }
            for ( var j=startY; j>stopY; j-=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
            }
        }
        smoothScroll(id);
        this.setState({ menu: false });
    }

    render() {
        const { menu } = this.state;

        return (
            <div>
                <div
                    className='hamburger-container'
                    onClick={this.handleOpenClick}
                >
                    <MenuSVG />
                </div>

                <div className={`menu-container ${menu ? 'menu-container-open' : ''}`}>

                    <div className='menu-inner-container'>
                        <p className='menu-close' onClick={this.handleCloseClick}>X</p>

                        <Link to='/solutions'>
                          <div className='menu-tab-container'>
                              <p>Solutions</p>
                          </div>
                        </Link>
                        <Link to='/about-us'>
                          <div className='menu-tab-container'>
                              <p>About Us</p>
                          </div>
                        </Link>
                        <Link to='/contact-us'>
                          <div className='menu-tab-container'>
                            <p>Contact Us</p>
                          </div>
                        </Link>
                        <Link to='/careers'>
                          <div className='menu-tab-container'>
                              <p>Careers</p>
                          </div>
                        </Link>

                    </div>
                </div>
                { menu && <div className='menu-overlay' onClick={this.handleCloseClick}></div> }
            </div>
        )
    }
}

export default Menu;