import React, { Component } from 'react';
import TimelineMax from 'gsap/TimelineMax';
import TweenLite from 'gsap/TweenLite';
import DrawSVGPlugin from '../../utilities/DrawSVGPlugin';
import CSSPlugin from '../../utilities/CSSPlugin';

// Styles
import './svg.css';

class AvataLoader extends Component {

    componentDidMount() {
        var tl = new TimelineMax();
        tl.add( TweenLite.from('.loader-section-1', 0.2, {drawSVG: 0, delay: 1}) );
        tl.add( TweenLite.from('.loader-section-2', 0.2, {drawSVG: 0, delay: 0.2}) );
        tl.add( TweenLite.from('.loader-section-3', 0.2, {drawSVG: 0, delay: 0.2}) );
        tl.add( TweenLite.from('.loader-section-4', 0.2, {drawSVG: 0, delay: 0.2}) );
        tl.add( TweenLite.from('.loader-section-5', 0.2, {drawSVG: 0, delay: 0.2}) );
        tl.add( TweenLite.from('.loader-section-6', 0.2, {drawSVG: 0, delay: 0.2}) );

        tl.add( TweenLite.from('.loader-section-7', 0.6, {drawSVG: 0, delay: 0.5}) );
        tl.add( TweenLite.from('.loader-section-8', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-9', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-10', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-11', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-12', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-13', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-14', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-15', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-16', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-17', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-18', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-19', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-20', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-21', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-22', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-23', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-24', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-25', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-26', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-27', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-28', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-29', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-30', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-31', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-32', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-33', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.from('.loader-section-34', 0.1, {drawSVG: 0}) );
        tl.add( TweenLite.to('.orange', 4, {opacity: 0}) );
    }

    render() {
        return (
            <svg width="100%" height="100%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 551.8 485.5">
                <polyline className="loader-section loader-section-1 orange" points="33.7,168.4 87.4,88.1 123.9,174.1 "/>
                <polyline className="loader-section loader-section-2 orange" points="92.9,79.9 131.7,167.5 213.9,22.8 "/>
                <polyline className="loader-section loader-section-3 orange" points="137.1,176.9 222.6,26 386.3,165.6 "/>
                <line className="loader-section loader-section-4 orange" x1="364.4" y1="33.2" x2="386.6" y2="154.6"/>
                <polyline className="loader-section loader-section-5 orange" points="298.2,10.8 364.1,33.4 458,53.5 "/>
                <polyline className="loader-section loader-section-6 orange" points="403.9,160.1 463.1,60.1 502.1,136.9 "/>

                <polygon className="loader-section loader-section-7" points="361.3,476.9 287.9,347.4 182.4,326.5 75.6,325.4 5,234.4 26.2,171.9 27,110 88.1,80.7 139.8,29 221.5,18.4 298.5,6.7 368.9,30.7 463.5,50.8 507.8,138.7 545.2,187.3 511.8,265.1 509.5,300.3 468.5,344.7 "/>
                <line className="loader-section loader-section-8" x1="26.2" y1="171.9" x2="88.1" y2="80.7"/>
                <line className="loader-section loader-section-9" x1="75.6" y1="325.4" x2="26.2" y2="171.9"/>
                <line className="loader-section loader-section-10" x1="131.1" y1="178.1" x2="75.6" y2="325.4"/>
                <line className="loader-section loader-section-11" x1="26.2" y1="171.9" x2="131.1" y2="178.1"/>
                <line className="loader-section loader-section-12" x1="88.1" y1="80.7" x2="131.1" y2="178.1"/>
                <line className="loader-section loader-section-13" x1="139.8" y1="29" x2="131.1" y2="178.1"/>
                <line className="loader-section loader-section-14" x1="221.5" y1="18.4" x2="131.1" y2="178.1"/>
                <line className="loader-section loader-section-15" x1="131.1" y1="178.1" x2="182.4" y2="326.5"/>
                <line className="loader-section loader-section-16" x1="251.1" y1="217.7" x2="131.1" y2="178.1"/>
                <line className="loader-section loader-section-17" x1="221.5" y1="18.4" x2="251.1" y2="217.7"/>
                <line className="loader-section loader-section-18" x1="182.4" y1="326.5" x2="251.1" y2="217.7"/>
                <line className="loader-section loader-section-19" x1="287.9" y1="347.4" x2="251.1" y2="217.7"/>
                <line className="loader-section loader-section-20" x1="251.1" y1="217.7" x2="394" y2="166.9"/>
                <line className="loader-section loader-section-21" x1="394" y1="166.9" x2="221.5" y2="18.4"/>
                <line className="loader-section loader-section-22" x1="468.5" y1="344.7" x2="370.5" y2="251.7"/>
                <line className="loader-section loader-section-23" x1="368.9" y1="30.7" x2="394" y2="168"/>
                <line className="loader-section loader-section-24" x1="463.5" y1="50.8" x2="394" y2="166.9"/>
                <line className="loader-section loader-section-25" x1="507.8" y1="138.7" x2="394" y2="166.9"/>
                <line className="loader-section loader-section-26" x1="511.8" y1="265.1" x2="394" y2="166.9"/>
                <line className="loader-section loader-section-27" x1="370.5" y1="251.7" x2="394" y2="166.9"/>
                <line className="loader-section loader-section-28" x1="251.1" y1="217.7" x2="370.5" y2="251.7"/>
                <line className="loader-section loader-section-29" x1="287.9" y1="347.4" x2="370.5" y2="251.7"/>
                <line className="loader-section loader-section-30" x1="385.1" y1="362.2" x2="370.5" y2="251.7"/>
                <line className="loader-section loader-section-31" x1="468.5" y1="344.7" x2="385.1" y2="362.2"/>
                <line className="loader-section loader-section-33" x1="468.5" y1="344.7" x2="370.5" y2="251.7"/>
                <line className="loader-section loader-section-33" x1="287.9" y1="347.4" x2="385.1" y2="362.2"/>
                <line className="loader-section loader-section-34" x1="361.3" y1="476.9" x2="385.1" y2="362.2"/>
            </svg>
        )
    }
}

export default AvataLoader;