import React, { Component } from 'react';
import {Link} from 'react-router-dom';

//Styles
import './privacy-policy.css';

class TermsOfUse extends Component {

  render() {
    return (
      <div className='wrapper'>
      <h2 className='title'>Privacy Policy</h2>
      <br />
      <p className='text'>Protecting your private information is our priority. This Statement of Privacy applies to Avata Intelligence and www.avataai.com and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Avata Intelligence include Avata. By using the www.avataai.com website, you consent to the data practices described in this statement.</p>
      <br />
      <h3 className='mid-title'>Collection of your Personal Information</h3>
      <p className='text'>www.avataai.com may also collect anonymous demographic information, which is not unique to you, such as your geographical location, gender, and age group. We may gather additional personal or non-personal information in the future.</p>
      <br />
      <p className='text'>Information about your computer hardware and software may be automatically collected by www.avataai.com. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of service, and to provide general statistics regarding use of the www.avataai.com website.</p>
      <br />
      <p className='text'>Avata encourages you to review the privacy statements of websites you choose to link to from www.avataai.com so that you can understand how those websites collect, use, and share your information. Avata is not responsible for the privacy statements or other content on websites outside of the www.avataai.com website.</p>
      <br />
      <h3 className='mid-title'>Use of your Personal Information</h3>
      <p className='text'>Avata collects and uses your personal information to operate its website and deliver the services you have requested.</p>
      <br />
      <p className='text'>Avata may also use your personally identifiable information to inform you of other products or services available from Avata and its affiliates. Avata may also contact you via surveys to conduct research about your opinion of current services or of potential new services that may be offered.</p>
      <br />
      <p className='text'>Avata Intelligence does not sell, rent, or lease its customer lists to third parties.</p>
      <br />
      <p className='text'>Avata may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your unique personally identifiable information (e-mail, name, address, telephone number) is transferred to the third party. Avata may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Avata, and they are required to maintain the confidentiality of your information.</p>
      <br />
      <p className='text'>Avata may keep track of the websites and pages our users visit within www.avataai.com, in order to determine what Avata services are the most popular. This data is used to deliver customized content and advertising within www.avataai.com to customers whose behavior indicates that they are interested in a particular subject area.</p>
      <br />
      <p className='text'>Avata may keep track of the websites and pages our users visit within www.avataai.com, in order to determine what Avata services are the most popular. This data is used to deliver customized content and advertising within www.avataai.com to customers whose behavior indicates that they are interested in a particular subject area.</p>
      <br />
      <p className='text'>Avata will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Avata or the Site; (b) protect and defend the rights or property of Avata; and, (c) act under exigent circumstances to protect the personal safety of users of www.avataai.com, or the public.</p>
      <br />
      <h3 className='mid-title'>Security of your Personal Information</h3>
      <p className='text'>www.avataai.com secures your personal information from unauthorized access, use, or disclosure.</p>
      <br />
      <h3 className='mid-title'>Children Under Thirteen</h3>
      <p className='text'>Avata and www.avataai.com does not knowingly collect personally identifiable information from children under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian for permission to use this website.</p>
      <br />
      <h3 className='mid-title'>Opt-Out & Unsubscribe</h3>
      <p className='text'>We respect your privacy and give you an opportunity to opt-out of receiving announcement of certain information. Users may opt-out of receiving any or all communications from www.avata.ai by contacting us here:</p>
      <b className='b-text'>Web Page: </b>
      <Link className='text' to='/contact-us'>Contact Us</Link>
      <br />
      <b className='b-text'>Email: </b>
      <a className='text' href='mailto:support@avata.ai'>support@avata.ai</a>
      <br />
      <br />
      <h3 className='mid-title'>Changes to this Statement</h3>
      <p className='text'>www.avataai.com will occasionally update this Statement of Privacy to reflect company and customer feedback. Avata Intelligence encourages you to periodically review this Statement to be informed of how www.avataai.com is protecting your information.</p>
      <br />
      <h3 className='mid-title'>Contact Information</h3>
      <p className='text'>Avata Intelligence welcomes your questions or comments regarding this Statement of Privacy. If you believe that Avata Intelligence has not adhered to this Statement, please contact us via our contact page.</p>
      <br />
      <i className='text'>Effective as of: 25 August 2016</i>

      </div>
    );
  }
}

export default TermsOfUse;