import React from 'react';

// Styles
import './svg.css';

export default (props) => {
    return (
        <svg width="100%" height="100%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 49.9 34.9">
            <g>
                <path d="M0,5h49.9V0H0V5z M0,20h49.9v-5H0V20z M0,34.9h49.9v-5H0V34.9z"/>    
            </g>
        </svg>
    )
}