
import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Redirect, Switch } from 'react-router-dom'

import { loadReCaptcha } from 'react-recaptcha-google';


// Components
import Home from './pages/home'
import Contact from './pages/contact'
import PrivacyPolicy from './pages/privacy-policy'
import TermsOfUse from './pages/terms-of-use'
import ScrollToTop from './components/scroll-to-top'

// Styles
import './App.css';

class App extends Component {

  componentDidMount() {loadReCaptcha(); loadReCaptcha();}
  render() {
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path='/contact-us' component={Contact} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Redirect to="/" />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;