import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import './footer.css';

const Footer = (props) => {
    return (
        <section>
            <div className='footer-wrapper'>
                <div className='footer-inner-container'>
                    <ul className='media-link-container'>
                        <a href='https://twitter.com/AvataIntel' target='_blank' rel="noopener noreferrer">
                            <li className='twitter-logo-container'>
                            </li>
                        </a>
                        <a href='https://www.facebook.com/avataai/' target='_blank' rel="noopener noreferrer">
                            <li className='facebook-logo-container'>
                            </li>
                        </a>
                        <a href='https://www.linkedin.com/company/avata-intelligence/' target='_blank' rel="noopener noreferrer">
                            <li className='linkedin-logo-container'>
                            </li>
                        </a>
                    </ul>
                    <div className='bottom-footer-text-container'>
                        <b className='bottom-footer-text'>® 2020 All Rights Reserved. Avata Intelligence.</b>
                          <Link className='footer-link' to='/privacy-policy'>Privacy Policy</Link>
                        <b className='bottom-footer-sep'>|</b>
                          <Link className='footer-link' to='/terms-of-use'>Terms of Use</Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;