import React, { Component } from 'react';

// Components
import Header from '../components/header';
import Footer from '../components/footer';
import Privacypolicy from '../components/privacy-policy';

// Styles
import '../App.css';

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="privacy-policy">
        <Header />
        <Privacypolicy />
        <Footer />
      </div>
    );
  }
}

export default PrivacyPolicy;