import React, { Component } from 'react';

// Components
import Header from '../components/header';
import ContactAcc from '../components/contact-accept';
import Footer from '../components/footer';

// Styles
import '../App.css';

class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <Header />
        <ContactAcc />
        <Footer />
      </div>
    );
  }
}

export default Contact;