import React from 'react'

// Styles
import './banner.css';

const Banner = (props) => {
    function currentYPosition() {
        // Firefox, Chrome, Opera, Safari
        if (window.pageYOffset) return window.pageYOffset;
        // Internet Explorer 6 - standards mode
        if (document.documentElement && document.documentElement.scrollTop)
            return document.documentElement.scrollTop;
        // Internet Explorer 6, 7 and 8
        if (document.body.scrollTop) return document.body.scrollTop;
        return 0;
    }
    
    function elmYPosition(eID) {
        var elm = document.getElementById(eID);
        if (elm) {
            var y = elm.offsetTop;
            var node = elm;
            while (node.offsetParent && node.offsetParent !== document.body) {
                node = node.offsetParent;
                y += node.offsetTop;
            } return y;
        }
    }
    
    function smoothScroll(eID) {
        var startY = currentYPosition();
        var stopY = elmYPosition(eID);
        var distance = stopY > startY ? stopY - startY : startY - stopY;
        if (distance < 100) {
            scrollTo(0, stopY); return;
        }
        var speed = Math.round(distance / 100);
        if (speed >= 20) speed = 20;
        var step = Math.round(distance / 25);
        var leapY = stopY > startY ? startY + step : startY - step;
        var timer = 0;
        if (stopY > startY) {
            for ( var i=startY; i<stopY; i+=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY += step; if (leapY > stopY) leapY = stopY; timer++;
            } return;
        }
        for ( var j=startY; j>stopY; j-=step ) {
            setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
            leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
        }
    }

    function scrollTo(id) {
        smoothScroll(id)
    }

    return (
        <div id='banner-section' className='banner-wrapper'>
            {/* <div className='banner-background'>
                <BannerSVG />
            </div> */}
            <div className='spacer'></div>
            <h2 className='page-title'>AVATA INTELLIGENCE acquired by PROCORE TECHNOLOGIES</h2>
            <h4 className='page-text'>Earlier this year, we (Procore) acquired Avata Intelligence, 
                an AI and machine learning firm, and brought world-class AI talent into Procore. 
                We’ve since introduced AI across several products. Our initial success... <a classname='page-text-link' href="https://blog.procore.com/procores-business-transformation-a-message-from-procore-founder-president-and-ceo-tooey-courtemanche-to-our-customers-and-partners/">Continue Reading</a></h4>
            
            <form action="https://www.procore.com">
                <button className='banner-button'><input className='banner-image' alt='Procore'></input><span>Join us @ Procore</span></button>
            </form>

        </div>
    )
}

export default Banner;