import React, { Component } from 'react';

// Components
import Header from '../components/header';
import Footer from '../components/footer';
import TermsofUse from '../components/terms-of-use';

// Styles
import '../App.css';

class TermsOfUse extends Component {

  render() {
    return (
      <div className="terms-of-use">
        <Header />
        <TermsofUse />
        <Footer />
      </div>
    );
  }
}

export default TermsOfUse;