import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

// components
import Menu from "../menu";
import AvataLoader from '../svg/avata-loader';

// Styles
import './header.css';

const Header = (props) => {
    function currentYPosition() {
        // Firefox, Chrome, Opera, Safari
        if (window.pageYOffset) return window.pageYOffset;
        // Internet Explorer 6 - standards mode
        if (document.documentElement && document.documentElement.scrollTop)
            return document.documentElement.scrollTop;
        // Internet Explorer 6, 7 and 8
        if (document.body.scrollTop) return document.body.scrollTop;
        return 0;
    }

    function elmYPosition(eID) {
        var elm = document.getElementById(eID);
        if (elm) {
            var y = elm.offsetTop;
            var node = elm;
            while (node.offsetParent && node.offsetParent !== document.body) {
                node = node.offsetParent;
                y += node.offsetTop;
            } return y;
        }
    }

    function smoothScroll(eID) {
        var startY = currentYPosition();
        var stopY = elmYPosition(eID);
        var distance = stopY > startY ? stopY - startY : startY - stopY;
        if (distance < 100) {
            scrollTo(0, stopY); return;
        }
        var speed = Math.round(distance / 100);
        if (speed >= 20) speed = 20;
        var step = Math.round(distance / 25);
        var leapY = stopY > startY ? startY + step : startY - step;
        var timer = 0;
        if (stopY > startY) {
            for ( var i=startY; i<stopY; i+=step ) {
                setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
                leapY += step; if (leapY > stopY) leapY = stopY; timer++;
            } return;
        }
        for ( var j=startY; j>stopY; j-=step ) {
            setTimeout("window.scrollTo(0, "+leapY+")", timer * speed);
            leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
        }
    }

    function scrollTo(id) {
        smoothScroll(id)
    }

    return (
        <Fragment>
            <div className='blue-angle'></div>
            <div className='header-wrapper'>
                <div className='header-inner-container'>
                    <div className='header-left'>
                        <Link to='/'>
                            <div className='header-logo-container'>
                                <AvataLoader />
                            </div>
                        </Link>
                    </div>
                    <div className='header-links-container'>
                        <Link className='header-link' to='/contact-us'>Contact Us</Link>
                    </div>
                    <Menu />
                </div>
            </div>
        </Fragment>
    )
}

export default Header;
