import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-google';
import GoogleMapReact from 'google-map-react';
import InputMask from 'react-input-mask';

//Styles
import './contact-accept.css';

class ContactAccept extends Component {

  constructor(props) {
    super(props);
    this.state = {
        First:"",
        Last:"",
        PhoneNumber:"",
        EmailAddress:"",
        Message:"",
        recap: "",
        isHidden: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRegSubmit = this.handleRegSubmit.bind(this);

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  componentDidMount() {
      if (this.captchaDemo) {
          this.captchaDemo.reset();

      }
  }

  onLoadRecaptcha() {
      if (this.captchaDemo) {
          this.captchaDemo.reset();
      }
  }

  verifyCallback(recaptchaToken) {
    if(recaptchaToken.length > 0){
      this.setState({
        recap: recaptchaToken
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleRegSubmit(event) {
    const emailBody = {
      first: this.state.First,
      last: this.state.Last,
      email: this.state.EmailAddress,
      phone: this.state.PhoneNumber,
      message: this.state.Message
    };

    fetch("https://cors-anywhere.herokuapp.com/https://ctryj4oxag.execute-api.us-west-2.amazonaws.com/dev/", {
        mode: "cors",
        method:"POST",
        headers: {
          'content-type':'application/json',
        },
        body: JSON.stringify({
                               "toAddresses":
                                 ["info@avata.ai"]
                               ,
                               "ccAddresses":
                                 []
                               ,
                               "subject": "Contact-Us",
                               "body": JSON.stringify(emailBody),
                               "recaptcha": JSON.stringify(this.state.recap)
                             })
      })
    this.setState({
      isHidden: true
    });
    event.preventDefault();
  }

  static defaultProps = {
    center: {
      lat: 33.997346,
      lng: -118.478559
    },
    zoom: 9
  };

  renderMarkers(map,maps){
    new maps.Marker({
      position: this.props.center,
      map,
      title: 'Hello World!'
    });
  }

  canBeSubmitted(){
    const {First, Last, PhoneNumber, EmailAddress, Message, recap} = this.state;
    return(
      First.length > 0 &&
      Last.length > 0 &&
      PhoneNumber.length === 14 &&
      EmailAddress.indexOf('@') !== -1 &&
      Message.length > 0 &&
      recap !== ""
    );
  }

  render() {
    const isEnabled = this.canBeSubmitted();

    return (
      <div className = 'contact-collect-wrapper'>
        <div className='map-wrapper' style={{height: '400px', width:'100%', position:'relative'}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyA0723C9AKUQx0i3rXtgKvcfkrLZ65AQl4'}}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map,maps)}
            >
          </GoogleMapReact>
        </div>
        <div className='top-wrapper'>
          <div className='top-text-wrapper'>
            <div className='sub-top-text-wrapper'>
              <h5 className='cta-txt'>GET IN TOUCH</h5>
              <h3 className='cont-title'>Contact Us</h3>
              <div className='supp-text-wrapper'>
                <b className='supp-bold-text'>Address: </b>
                <b className='supp-text'>212 Main Street</b>
                <p className='supp-text'>Venice, CA 90291</p>
                <br/>
                <b className='supp-bold-text'>Email: </b>
                <a href='mailto:info@avata.ai'>info@avata.ai</a>
              </div>
            </div>
          </div>
          <div className = 'contact-info-wrapper'>
            {!this.state.isHidden &&
              <form className='input-form'>
                <label className='name-label'>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <h5 className='input-header'> Name* </h5>
                        </td>
                      </tr>
                      <tr>
                        <td className='tbl-cell'>
                          <input
                            required
                            name="First"
                            type="text"
                            onChange={this.handleInputChange}
                            className='reg-box'
                          />
                          <p className='firstLast'>First</p>
                        </td>
                        <td className='tbl-cell'>
                          <input
                            required
                            name="Last"
                            type="text"
                            onChange={this.handleInputChange}
                            className='reg-box'
                          />
                          <p className='firstLast'>Last</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </label>
                <br />
                <label className='phone-email-label'>
                  <table>
                    <tbody>
                      <tr>
                        <td className='tbl-cell'>
                          <h5 className='input-header'> Phone<br />Number* </h5>
                          <InputMask
                            mask="(999) 999-9999"
                            required
                            name="PhoneNumber"
                            onChange={this.handleInputChange}
                            className='reg-box'
                          />
                        </td>
                        <td className='tbl-cell'>
                          <h5 className='input-header'> Email<br />Address* </h5>
                          <input
                            required
                            name="EmailAddress"
                            type="text"
                            onChange={this.handleInputChange}
                            className='reg-box'
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </label>
                <br />
                <table>
                  <tbody>
                    <tr>
                      <td className='big-cell'>
                        <label>
                          <h5 className='input-header'> Message* </h5>
                          <textarea
                            required
                            name="Message"
                            type="text"
                            style={{height: 200}}
                            onChange={this.handleInputChange}
                            className='big-box'>
                          </textarea>
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div className='recaptchaa'>
                  <ReCaptcha
                    className='recaptcha'
                    ref={(el) => {this.captchaDemo = el;}}
                    render="explicit"
                    sitekey="6LcCw6sUAAAAAHKcuNg1lHoY9iGMDaqgPv6klA_D"
                    onloadCallback={this.onLoadRecaptcha}
                    verifyCallback={this.verifyCallback}
                  />

                </div>
                <br />
                <input type="submit" onClick={this.handleRegSubmit} disabled={!isEnabled} className='submit-button'/>
              </form>
            }
            {this.state.isHidden && <h5 className='ty-txt'>Thank you for your interest! We will respond to you shortly.</h5>}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactAccept;